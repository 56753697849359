<template>
  <v-card
    elevation-0
    class="report"
  >
    <v-flex
      xs12
      mx-3
      pt-4
    >
      <span class=" tw-ml-0 tw-font-semibold tw-text-lg">
        List of accounts where the last call was held more than twelve months ago.
      </span>
    </v-flex>
    <v-layout
      px-3
      row
    >
      <v-flex
        xs6
        class="tw-pl-3 tw-pt-3 tw-inline-flex"
      >
        <v-select

          v-model="staffSelected"

          :items="staff"
          item-text="name"
          item-value="user_name"

          label="Staff member"
          @change="getList"
        />
        <v-checkbox
          v-model="callDmu" 
          :disabled="!staffSelected && !loading" 
          class="pl-4"
          label="Last call with a DMU"
          @change="getList"
        />
      </v-flex>
    </v-layout>
    <v-data-table
      :headers="headers"
      :items="companies"
      :items-per-page="5"
    >
      <template #item="{ item }">
        <tr class=" tw-cursor-pointer tw-font-normal">
          <td @click="click(item.id)">
            {{ item.name }}
          </td>
          <td
            width="90px"
            class="text-center"
            @click="click(item.id)" 
          >
            {{ item.rank }}
          </td>
          <td
            width="200px"
            class="text-right"
            @click="click(item.id)"
          >
            {{ formatDate(item.date_start) }}
          </td>
        </tr>
      </template>
      <template #no-data>
        <div v-if="!staffSelected">
          <v-row justify="center">
            <v-col cols="auto">
              <div class="tw-pt-3 tw-text-base">
                Select a staff member to view the report
              </div>
              <v-lottie-player
                :loop="true"
                width="350px"
                height="350px"
                :animation-data="require('@/assets/animations/astronaut.json')"
              />
            </v-col>
          </v-row>
        </div>
        <div v-else-if="companies.length == 0 && !loading">
          No records found
        </div>
      </template>
    </v-data-table>
    <v-dialog
      v-model="loading"
      max-width="480px"
      persistent
    >
      <v-card>
        <v-card-title class="subheading justify-center pt-3 pb-3">
          We've got this. Sing like no one is listening...
        </v-card-title>
        <v-progress-linear
          :indeterminate="true"
          height="4"
          class="pa-0 ma-0"
          color="blue"
        />
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>

export default {

  data () {
    return {
      reportName: 'Last Call',
      staffSelected: '',
      staff: '',
      callDmu: null,
      companies: [],
      loading: false,
      headers: [
        {
          text: 'Account',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Rank',
          align: 'right',
          sortable: false,
          value: 'rank'
        },
        {
          text: 'Last Call',
          align: 'right',
          sortable: false,
          value: 'date_start'
        }
      ],

    }
  },
  computed: {
    token () {
      return this.$store.getters.token
    }
  },

  // watch: {
  //   staffSelected (staff) {
  //     const headers = {
  //     'Content-Type': 'application/json',
  //     'OAuth-Token': this.$store.getters.token 
  //   }
  //     this.loading = true
  //     this.$http.post(this.$api_url + 'reports/companies/last_call', {
  //       user: staff,
  //       call_type: (this.callDmu ? 'convDMU' : null),
  //       call_status: 'Held',
  //       date: this.$moment().subtract(9, 'Months')
  //     }, {headers: headers})
  //       .then(response => {
  //         this.companies = response.data
  //         this.loading = false
  //       })
  //     // this.dateFormatted = this.formatDate(this.date)
  //   }
  // },

  mounted() {
      this.$http.get(this.$api_url + 'sales-staff', {
      headers: {
        'OAuth-Token': this.token
      }
    })
      .then(response => {
        this.staff = response.data
      })
  },
  methods: {
    formatDate (date) {
      if (!date){
        return 'No calls with DMU'
      }else{
        return this.$moment(date).format('DD/MM/YYYY')
      }
     
    },
    click (item) {
      var win = window.open('http://sugar.xprt.com/#Accounts/' + item, '_blank')
      win.focus()
    },
    async getList() {
      console.log('change')
      console.log(this.staff)
      const headers = {
      'Content-Type': 'application/json',
      'OAuth-Token': this.$store.getters.token 
    }
      this.loading = true
      await this.$http.post(this.$api_url + 'reports/companies/last_call', {
        user: this.staffSelected,
        call_type: (this.callDmu ? 'convDMU' : null),
        call_status: 'Held',
        date: this.$moment().subtract(9, 'Months')
      }, {headers: headers})
        .then(response => {
          this.companies = response.data
          this.loading = false
        })
      // this.dateFormatted = this.formatDate(this.date)
    } 
  }
}
</script>

<style scoped>
.report {
  font-size: 14px;
  font-weight: 700 !important;
}

.v-icon .v-data-table-header__icon .mdi-arrow-up  {
  margin-bottom: 5px !important;
}
</style>